import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import type { Campaign } from '~/server/transformers/shop/campaignTransformer';
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';
import type { AddCampaignEvent } from '~/stores/useCart';

export default function useEditCampaignDialog() {
  const dialogStore = useDialogStore();
  interface openOptions {
    lineItem?: LineItem;
    campaign?: Campaign;
  }

  interface Result {
    items: AddCampaignEvent['items'];
    quantity: number;
  }
  return {
    open: async (data: openOptions) => {
      return dialogStore.openDialog<Result>(DialogIdent.EDIT_CAMPAIGN, data);
    },
    getData: () => {
      return dialogStore.getDialogData<openOptions>(DialogIdent.EDIT_CAMPAIGN);
    },
    close: (items: AddCampaignEvent['items'] = null, quantity: number) => {
      dialogStore.closeDialog({ items, quantity });
    },
    isOpen: () => {
      return (
        dialogStore.open &&
        dialogStore.dialogIdent === DialogIdent.EDIT_CAMPAIGN
      );
    },
  };
}
